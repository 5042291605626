import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Container = styled.div`
  margin-top: ${styles.spaces.xLarge};
  margin-bottom: ${styles.spaces.xLarge};
  @media ${styles.breakpoints.mobile} {
    margin-top: ${styles.spaces.medium};
    margin-bottom: ${styles.spaces.large};
  }
`;

class ImgComponent extends React.Component {
  render() {
    const { img, alt, style } = this.props;
    return (
      <Container>
        <img className={'imgFit ' + style} src={img} alt={alt} />
      </Container>
    );
  }
}

export default ImgComponent
