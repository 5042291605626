import React from 'react'
import Intro from '../../components/IntroHome';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Title2 from '../../components/Title2';
import ImgFull from '../../components/ImgFull';
import ImgFit from '../../components/ImgFit';
import Iframe from '../../components/Iframe';
import Section from '../../components/Section';
import SectionSemiFullFit from '../../components/SectionSemiFullFit';
import Helmet from 'react-helmet';

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="notch">
        <Helmet title={`About | ${data.site.siteMetadata.title}`}
          bodyAttributes={{ class: 'about' }}
        />
        <Intro dark bg='#161616' title='Hola, nice to meet you'>
          <p>Hi, nice to meet you. This is my story</p>
        </Intro>
        <Section>
          <Title2 title='First years' />
          <p>I started working as a designer for different agencies when living in Spain. I had the opportunity to work closely with clients like Paramount Channel, Coca-Cola, Teatro Real or RACE. Technology changed, and the first iPhone arrived. People started to interact with each other using new channels and companies needed to adapt to the changing market conditions.</p>
          <p>The next years I focused on my own projects, including a startup offering online personal training and a forecasting service based on personalised meteorological predictions.</p>
        </Section>
        <SectionSemiFullFit>
          <ImgFull fit img={data.cover.childImageSharp.fluid.src} alt='Laos, an awesome country to visit by motorcycle' />
        </SectionSemiFullFit>
        <Section>
          <Title2 title='On the road' />
          <p><strong>In 2013</strong> my wife and I decided to embark on a trip <a href="http://desdelamoto.com">from Spain to Vietnam</a> by motorcycle.</p>
          <Iframe>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/0eoOzPVbJds" frameborder="0" allow="autoplay; encrypted-media; "></iframe>
          </Iframe>
          <p>It was a great experience where we met unbelievable people, and we also had a lot of time to plan the next steps. A year on a bike is a perfect break, and it was in Thailand where we decided our next move.
        </p>
          <Title2 title='Australia' />
          <ImgFit img={data.jv.childImageSharp.fluid.src} alt='Family life' />
          <p>Here we are. I'm currently working at <a href="http://nirovision.com">Nirovision</a> in Sydney. My wife is working as a Spanish teacher; she loves it. And our daughter Clara was born in February 2018. Exciting times!</p>
          <p>Thanks for passing by,<br />Javier Alvear</p>
        </Section>
      </div>
    </Layout>
  )
};

export default AboutPage


export const query = graphql`
  query GatsbyAboutQuery {
    site {
      siteMetadata {
        title
      }
    }

    cover: file(relativePath: { eq: "about/laos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    jv: file(relativePath: { eq: "about/withclara.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }
    australia: file(relativePath: { eq: "about/boat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          src
        }
      }
    }

  }
`;