import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Container = styled.div`
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  margin-top: ${styles.spaces.xLarge};
  margin-bottom: ${styles.spaces. xLarge};
  @media ${styles.breakpoints.mobile} {
    margin-top: ${styles.spaces.large};
    margin-bottom: ${styles.spaces.large};
  }
  & iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
`;

class IframeComponent extends React.Component {
  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    );
  }
}

export default IframeComponent
