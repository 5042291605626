import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';

const Container = styled.div`
  background: ${props => props.bg ? props.bg : 'white'};
  margin: 0 auto;
  padding-top: 0;
  padding-right: ${styles.spaces.xLarge};
  padding-bottom: 0;
  padding-left: ${styles.spaces.xLarge};
  & h2 {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & p {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  @media ${styles.breakpoints.mobile} {
    padding-top: ${styles.spaces.small};
    padding-right: ${styles.spaces.medium};
    padding-bottom: ${styles.spaces.small};
    padding-left: ${styles.spaces.medium};
  }
`;

class SectionSemiFullFitComponent extends React.Component {
  render() {
    return (
      <Container {...this.props}>
        {this.props.children}
      </Container>
    );
  }
}

export default SectionSemiFullFitComponent
